<template>
  <div class="customFields-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-2">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getCustomFields"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="dataTables_filter text-md-right">
                  <b-button variant="primary" type="button" size="sm" @click="newCustomField">
                    <i class="uil uil-plus"></i> New Custom Field
                  </b-button>
                  <div class="d-inline-flex align-items-center">
                    <b-form-input v-model="datatable.queries.search" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </div>
                  <button class="ml-1 btn btn-primary btn-sm" @click="getCustomFields">
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingCustomFields">
              <b-table :items="customFields" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc" :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page" :no-local-sorting="true" @sort-changed="sortChanged">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:cell(active)="data">
                  <b-form-checkbox v-model="data.item.is_active" switch @change="handleActiveChange(data.item)">
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-dropdown v-if="data.item.type !== 'popup'" variant="black" right
                    toggle-class="arrow-none text-muted pr-0">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-v"></i>
                    </template>

                    <b-dropdown-item @click="edit(data.item)">
                      <i class="uil uil-edit mr-2"></i>Edit
                    </b-dropdown-item>

                    <b-dropdown-item :disabled="loadingRemove === data.item.id" variant="danger"
                      @click="destroy(data.item)">
                      <i class="uil uil-trash mr-2"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalCustomFields }} customFields
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalCustomFields"
                        :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="new-customField" v-model="showCustomFieldModal"
      :title="selectedCustomField ? ('Edit Custom Field \'' + selectedCustomField.name + '\'') : 'New Custom Field'"
      hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="customFieldForm">
        <form v-if="customField" class="customField-form" @submit.prevent="handleSubmit(submitData)">
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="customField.name" label="Name" rules="required" name="name" />
            </div>
            <div class="col-md-12">
              <ValidationProvider v-slot="{ failed, errors }" rules="required" name="fieldType">
                <div class="form-group">
                  <label>Field Type</label>
                  <multiselect v-model="fieldType" :options="fieldTypes" :class="{ 'is-invalid': failed }"
                    placeholder="Select Field Type" track-by="value" label="label" :allow-empty="false"></multiselect>
                  <b-form-invalid-feedback v-if="errors.length">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <b-form-group class="text-right">
            <b-button variant="primary" :disabled="loadingSubmit || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingSubmit" small />
              <span v-else>{{ selectedCustomField ? 'Update' : 'Create' }}</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Custom Field">
      <p v-if="selectedCustomField">Are you sure you want to delete custom field <b>"{{
        selectedCustomField.name
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>
    
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', label: 'Name', sortable: true, },
          { key: 'type_str', label: 'Type' },
          { key: 'active', label: 'Active' },
          { key: 'actions', thStyle: { width: '50px' } },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'asc',
        },
      },
      loadingSubmit: false,
      showCustomFieldModal: false,
      showDeleteModal: false,
      selectedCustomField: null,
      loadingCustomFields: false,
      loadingRemove: null,
      customField: null,
      fieldType: undefined,
    }
  },

  computed: {
    totalCustomFields() {
      return this.$store.getters['customField/total']
    },

    customFields() {
      return this.$store.getters['customField/all'] || []
    },

    fieldTypes() {
      return [
        { value: 1, label: 'String' },
        { value: 2, label: 'Date' },
      ]
    },
  },

  mounted() {
    this.getCustomFields()
  },

  methods: {
    handleActiveChange(item) {
      this.customField = {
        ...item,
      }
      this.$store.dispatch('customField/update', this.customField)
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
         })
        .catch(() => {
          item.is_active = !item.is_active
        })
    },
    newCustomField() {
      this.customField = {
        name: '',
      }
      this.fieldType = this.fieldTypes[0]
      this.selectedCustomField = null
      this.showCustomFieldModal = true
    },

    submitData() {
      this.loadingSubmit = true
      this.customField.type = this.fieldType ? this.fieldType.value : null
      if (!this.selectedCustomField) {
        this.$store
          .dispatch('customField/create', this.customField)
          .then((res) => {
            this.showCustomFieldModal = false
            this.$refs.customFieldForm.reset()
            this.loadingSubmit = false
            this.getCustomFields();
            this.customField.id = res.data.data.id
            this.$store
              .dispatch('auth/getProfile')
          })
          .catch((err) => {
            console.log(err);
            this.loadingSubmit = false
          })
      } else {
        this.$store
          .dispatch('customField/update', this.customField)
          .then(() => {
            this.showCustomFieldModal = false
            this.customField = null
            this.$refs.customFieldForm.reset()
            this.loadingSubmit = false
            this.getCustomFields();
            this.$store
              .dispatch('auth/getProfile')
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getCustomFields()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getCustomFields()
    },

    getCustomFields() {
      this.loadingCustomFields = true

      this.$store
        .dispatch('customField/getAll', this.datatable.queries)
        .then(() => {
          this.loadingCustomFields = false
        })
        .catch(() => {
          this.loadingCustomFields = false
        })
    },

    edit(customField) {
      this.customField = {
        ...customField
      }
      this.fieldType = this.fieldTypes.find(item => item.value === customField.type)
      this.selectedCustomField = customField
      this.showCustomFieldModal = true
    },

    destroy(customField) {
      this.showDeleteModal = true
      this.selectedCustomField = customField
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedCustomField.id

      this.$store
        .dispatch('customField/destroy', this.selectedCustomField.id)
        .then(() => {
          this.loadingRemove = null
          this.getCustomFields();
          this.$store
            .dispatch('auth/getProfile')
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss">
.coupon-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>  